import React, { useContext, useEffect } from 'react';
import { Grid, Divider } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

import * as audit from '@/libs/AuditTrailOperations';
import CONST from '@/const';
import TRANS_MAP from '@/i18n/TransMap';
import { AppContext } from '@/context/AppContext';

const AuditTrailFilters = ({ mode })  => {
    const { t } = useTranslation();
    const {
        tabFilter, setTabFilter,
        filterCounts, setFilterCounts,
        updateFilters,
    } = useContext(AppContext);

    // Set counts on the filter icons
    useEffect(() => {
        const handleFilterCounts = async () => {
            try {
                setFilterCounts(await audit.getFilterCounts(mode));
            } catch (error) {
                /* istanbul ignore next */
                console.error(error);
            }
        }
        handleFilterCounts();
    }, [setFilterCounts, mode]);

    const handleTabSelect = (e) => {
        setTabFilter(e);
        if (e === CONST.tabFilters.all)
            updateFilters({ key: CONST.auditTrail.status, value: "" })
        else if (e === CONST.tabFilters.validated)
            updateFilters({ key: CONST.auditTrail.status, value: CONST.status.printValidated })
        else if (e === CONST.tabFilters.notValidated)
            updateFilters({ key: CONST.auditTrail.status, value: audit.getNotValidatedStatusStr() })
        else if (e === CONST.tabFilters.failed)
            updateFilters({ key: CONST.auditTrail.status, value: audit.getErrorStatusStr() })
        else
            /* istanbul ignore next */
            updateFilters({ key: CONST.auditTrail.status, value: "" })
    }

    return (
        <Grid container
            direction="column"
            id="audit-trail-header"
        >

            <Grid container
                alignItems="flex-start"
                direction="column"
                justifyContent="space-between"
                id={CONST.tabFilters.all}
                onClick={(e) => (handleTabSelect(e.currentTarget.id))}
            >
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className="nb-label"
                >
                    <span id="all-ops-nb"
                        className={tabFilter === CONST.tabFilters.all ? "icon-blue" : ""}
                    >
                        {filterCounts.totalNb}
                    </span>
                    <div>{t(TRANS_MAP.filterAll)}</div>
                </Grid>
                {tabFilter === CONST.tabFilters.all && <div className="ink-bar" id="ib-blue" />}
            </Grid>

            <Divider id="header-divider" orientation="vertical" variant="middle" />

            <Grid container
                alignItems="flex-start"
                direction="column"
                justifyContent="space-between"
                className="ops-tabs"
                id={CONST.tabFilters.validated}
                onClick={(e) => (handleTabSelect(e.currentTarget.id))}
            >
                <Grid
                    justifyContent="center"
                    className="icon-nb-label"
                >
                    <div className="icon-and-nb">
                        {tabFilter === CONST.tabFilters.validated
                            ? <CheckCircleIcon className="ops-icons icon-green" fontSize="large" />
                            : <CheckCircleOutlineIcon className="ops-icons" fontSize="large" />
                        }
                        <span className="other-ops-nb">{filterCounts.validNb}</span>
                    </div>
                    <div className="tab-label">{t(TRANS_MAP.filterValidated)}</div>
                </Grid>
                {tabFilter === CONST.tabFilters.validated && <div className="ink-bar" id="ib-green" />}
            </Grid>

            <Grid container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className="ops-tabs"
                id={CONST.tabFilters.notValidated}
                onClick={(e) => (handleTabSelect(e.currentTarget.id))}
            >
                <Grid
                    justifyContent="center"
                    className="icon-nb-label"
                >
                    <div className="icon-and-nb">
                        {tabFilter === CONST.tabFilters.notValidated
                            ? <WarningIcon className="ops-icons icon-orange" fontSize="large" />
                            : <WarningAmberIcon className="ops-icons" fontSize="large" />
                        }
                        <span className="other-ops-nb">{filterCounts.warningNb}</span>
                    </div>
                    <div className="tab-label">{t(TRANS_MAP.filterNotValidated)}</div>
                </Grid>
                {tabFilter === CONST.tabFilters.notValidated && <div className="ink-bar" id="ib-orange" />}
            </Grid>

            <Grid container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className="ops-tabs"
                id={CONST.tabFilters.failed}
                onClick={(e) => (handleTabSelect(e.currentTarget.id))}
            >
                <Grid
                    justifyContent="center"
                    className="icon-nb-label"
                >
                    <div className="icon-and-nb">
                        {tabFilter === CONST.tabFilters.failed
                            ? <CancelIcon className="ops-icons icon-red" fontSize="large" />
                            : <CancelOutlinedIcon className="ops-icons" fontSize="large" />
                        }
                        <span className="other-ops-nb">{filterCounts.errorNb}</span>
                    </div>
                    <div className="tab-label">{t(TRANS_MAP.filterFailed)}</div>
                </Grid>
                {tabFilter === CONST.tabFilters.failed && <div className="ink-bar" id="ib-red" />}
            </Grid>
        </Grid>
    );
}

export default AuditTrailFilters;
