import React, { useContext, useState } from 'react';
import { API } from 'aws-amplify';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TuneIcon from '@mui/icons-material/Tune';
import DatePicker from 'react-datepicker';
import format from "date-fns/format";

import { AppContext } from '@/context/AppContext';
import * as utils from '@/libs/utils';
import * as custo from '@/libs/Customize';
import * as audit from '@/libs/AuditTrailOperations';
import TRANS_MAP from '@/i18n/TransMap';
import CONST from '@/const';
import useCustomize from "@/hooks/useCustomize";
import useKeyPress from "@/hooks/useKeyPress";

import 'react-datepicker/dist/react-datepicker.css';

const DATE_FORMAT = "dd.MM.yyyy";

const Form = () => {

    // Context states
    const {
        setOpenDialog,
        openAlertDialog,
        user,
        setOperation,
        setPrintings,
        receivingPlant, setReceivingPlant,
        material, setMaterial,
        setInboundField,
        batch, setBatch,
        manufacturingDate, setManufacturingDate,
        expirationDate, setExpirationDate,
        serialNb, setSerialNb,
        quantity, setQuantity,
    } = useContext(AppContext);

    const [isDisabled] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const PH_RECEIVINGPLANT = t(TRANS_MAP.inputReceivingPlantLabel);
    const PH_MATERIAL = t(TRANS_MAP.inputMaterialLabel);
    const PH_BATCH = t(TRANS_MAP.inputBatchInboundLabel);
    const PH_MANUFACTURING_DATE = t(TRANS_MAP.inputManufacturingDateLabel);
    const PH_EXPIRATION_DATE = t(TRANS_MAP.inputExpirationDateLabel);
    const PH_SERIALNB = t(TRANS_MAP.inputSerialNbLabel);
    const PH_QUANTITY = t(TRANS_MAP.inputQuantityLabel);


    const LABEL_RECEIVINGPLANT = t(TRANS_MAP.receivingPlantLabel);
    const LABEL_MANUFACTURING_DATE = t(TRANS_MAP.manufacturingDateLabel);
    const LABEL_EXPIRATION_DATE = t(TRANS_MAP.expirationDateLabel);
    const LABEL_SERIALNB = t(TRANS_MAP.serialNbLabel);
    const LABEL_MATERIAL = t(TRANS_MAP.materialLabel);
    const LABEL_BATCH = t(TRANS_MAP.batchLabel);
    const LABEL_CUST_BTN = t(TRANS_MAP.customizeButton);
    const LABEL_QUANTITY = t(TRANS_MAP.inboundQuantityLabel);

    const RECEIVINGPLANT_FIELD_NAME = "receivingPlant";
    const MATERIAL_FIELD_NAME = "material";
    const BATCH_FIELD_NAME = "batch";
    const MANUFACTURING_DATE_FIELD_NAME = "manufacturingDate";
    const EXPIRATION_DATE_FIELD_NAME = "expirationDate";
    const SERIALNB_FIELD_NAME = "serialNb";
    const QUANTITY_FIELD_NAME = "quantity";

    const receivingPlantInputHandler = (e) => setReceivingPlant(e.target.value.toUpperCase());
    const materialInputHandler = (e) => setMaterial(e.target.value.toUpperCase());
    const batchInputHandler = (e) => setBatch(e.target.value.toUpperCase());
    const serialNbInputHandler = (e) => setSerialNb(e.target.value.toUpperCase());
    const manufacturingDateInputHandler = (e) => setManufacturingDate(e);
    const expirationDateInputHandler = (e) => setExpirationDate(e);

    /* istanbul ignore next */
    const quantityInputHandler = (e) => {
        if (e.target.value < 1) {
            return;
        }
        setQuantity(e.target.value);
    }

    // Format material and batch inputs on focus out (e.g. in case of barcode scan)
    const handleMaterialInput = (e) => {
        try {
            const { item, newBatch } = utils.formatMaterialInput(e.target.value.toUpperCase());
            setMaterial(item);
            /* istanbul ignore next */
            if (newBatch)
                setBatch(newBatch.toUpperCase());
        } catch (error) {
            /* istanbul ignore next */
            console.error(`Error formating material input: ${e}`);
        }
    }

    // Check that required fields are filled
    const isFormValid = () => receivingPlant.trim() && material.trim();

    // Check that serial number and batch are not both filled
    const isBatchOrSerialNb = () => !utils.isEmptyOrSpaces(batch) && !utils.isEmptyOrSpaces(serialNb);

    const startCustomize = async () => {
        if (isBatchOrSerialNb()) {
            openAlertDialog({ title: TRANS_MAP.missingInputs, content: TRANS_MAP.errorBatchOrSerial });
            return;
        }

        const { matnr, ean } = utils.parseItem(material);

        // Check that item (product/material) and batch exist in SAP, and convert ean (if present) to product_number
        try {
            const response = await API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.apiRoutes.valid, {
                response: true,
                body: {
                    product_number: matnr,
                    lot_number: batch,
                    ean: ean,
                    mode: audit.AVAILABLE_MODE.IN
                }
            });
            let { product_number, lot_number, ean_number } = response.data;

            if (receivingPlant.length < 2) {
                openAlertDialog({ title: TRANS_MAP.warning, content: CONST.status.receivingPlantLength });
                return;
            }

            const country = receivingPlant.slice(0, 2);
            if (!product_number) {
                const errorStatus = CONST.status.badProduct;

                const badOperation = await audit.createOperation({ user, country, item: matnr, material, ean, batch, receiving_plant: receivingPlant, serial_number: serialNb, status: errorStatus, mode: audit.AVAILABLE_MODE.IN });
                await audit.createPrinting(({ operation: badOperation, material, status: errorStatus }));
                openAlertDialog({ title: TRANS_MAP.warning, content: errorStatus });
                return;
            }

            lot_number = lot_number ? lot_number : batch;

            const newOperation = await audit.createOperation({ user, country, item: product_number, ean, batch: lot_number, receiving_plant: receivingPlant, serial_number: serialNb, quantity: quantity, mode: audit.AVAILABLE_MODE.IN })

            // Set operation in App Context for further use in Printings Page
            setOperation(newOperation);

            // Look for documents in YMTA_CTRY_CODINB SAP Table
            let responseInboundCusto = await custo.getInboundCusto({
                matnr: product_number, receivingPlant
            });

            // No document found, update operation status, stop here
            if (!responseInboundCusto.length) {
                await audit.updateOperation({ user, operation: newOperation, status: CONST.status.noCusto })
                openAlertDialog({ title: TRANS_MAP.warning, content: CONST.status.noCusto });
                return;
            }

            // Handle documents datas from GLT
            const GLTDocumentsDatas = await custo.processGLTDocuments(responseInboundCusto);
            const newPrintings = await audit.handlePrintingsCreation({ operation: newOperation, RCDocumentsDatas: [], GLTDocumentsDatas });

            // Check that we have printings to display in the Printings table. If not, dialog with error is shown
            let { documentsCheck, statusCheck } = custo.checkDocumentsDatas([], GLTDocumentsDatas);
            if (!documentsCheck) {
                openAlertDialog({ title: TRANS_MAP.warning, content: statusCheck });
                return;
            }

            // Set documents datas into AppContext to use the values in the printings page
            setPrintings([...newPrintings]);
            setOpenDialog(false);
            setInboundField(
                {
                    GTIN: ean_number,
                    QTY: quantity,
                    SN: serialNb,
                    manufacturingDate: manufacturingDate && manufacturingDate !== "" ? format(manufacturingDate, DATE_FORMAT): null,
                    expiryDate: expirationDate && expirationDate !== "" ? format(expirationDate, DATE_FORMAT) : null,
                });
            setMaterial(product_number);
            // Move forward if there are custos (even if some documents are missing, it's shown in the printing table)
            history.push(CONST.frontRoutes.printingsInbound);
        } catch (error) {
            throw Object.assign(
                new Error(error),
                { message: error.response?.data || error.message }
            );
        }
    }


    // Preflight when clicking Customize button
    const handleCustomize = useCustomize(isFormValid, startCustomize);

    // Handling material input if enter key is press
    const keyPress = useKeyPress(event => (event.charCode === CONST.keyboardKeys.ENTER && event.target.name === MATERIAL_FIELD_NAME && material[0] === "]"), handleMaterialInput)

    return (
        <Grid container
            id="cod-form-container"
            direction="column"
        >
            <form onSubmit={handleCustomize} onKeyPress={keyPress}>
                <label>
                    {LABEL_RECEIVINGPLANT}<span className="star-required">*</span><br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            type="text"
                            maxLength={4}
                            name={RECEIVINGPLANT_FIELD_NAME}
                            onChange={receivingPlantInputHandler}
                            placeholder={PH_RECEIVINGPLANT}
                            value={receivingPlant}
                            required />

                    </Grid>
                </label>
                <label>
                    {LABEL_MATERIAL}<span className="star-required">*</span><br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={MATERIAL_FIELD_NAME}
                            maxLength={18}
                            type="text"
                            onChange={materialInputHandler}
                            onBlur={handleMaterialInput}
                            placeholder={PH_MATERIAL}
                            value={material}
                            required
                        />
                    </Grid>
                </label>
                <label>
                    {LABEL_BATCH}
                    <br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={BATCH_FIELD_NAME}
                            type="text"
                            maxLength={10}
                            onChange={batchInputHandler}
                            placeholder={PH_BATCH}
                            value={batch}
                        />
                    </Grid>
                </label>
                <label>
                    {LABEL_MANUFACTURING_DATE}
                    <br />
                    <Grid container
                        className="cod-form-input-container">
                        <DatePicker
                            dateFormat={DATE_FORMAT}
                            name={MANUFACTURING_DATE_FIELD_NAME}
                            wrapperClassName="cod-form-input"
                            className="form-datepicker"
                            onChange={manufacturingDateInputHandler}
                            placeholderText={PH_MANUFACTURING_DATE}
                            selected={manufacturingDate}
                            disabled={isDisabled}
                        />
                    </Grid>
                </label>
                <label>
                    {LABEL_EXPIRATION_DATE}
                    <br />
                    <Grid container
                        className="cod-form-input-container">
                        <DatePicker
                            dateFormat={DATE_FORMAT}
                            name={EXPIRATION_DATE_FIELD_NAME}
                            wrapperClassName="cod-form-input"
                            className="form-datepicker"
                            onChange={expirationDateInputHandler}
                            placeholderText={PH_EXPIRATION_DATE}
                            selected={expirationDate}
                            disabled={isDisabled}
                        />
                    </Grid>
                </label>
                <label>
                    {LABEL_SERIALNB}
                    <br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={SERIALNB_FIELD_NAME}
                            type="text"
                            onChange={serialNbInputHandler}
                            placeholder={PH_SERIALNB}
                            value={serialNb}
                        />
                    </Grid>
                </label>
                <label>
                    {LABEL_QUANTITY}
                    <br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={QUANTITY_FIELD_NAME}
                            type="number"
                            onChange={quantityInputHandler}
                            placeholder={PH_QUANTITY}
                            value={quantity}
                        />
                    </Grid>
                </label>
                <Grid item>
                    <Button type="submit" variant="contained" id="customize-btn">
                        <TuneIcon id="tune-icon" />
                        {LABEL_CUST_BTN}
                    </Button>
                </Grid>
            </form>
        </Grid>
    )
}

export default Form;
