import { useContext, } from 'react';
import { AppContext } from '@/context/AppContext';
import TRANS_MAP from '@/i18n/TransMap';
import useBusyDialog from "./useBusyDialog";

const useCustomize = (isFormValid, startCustomize) => {
    const { openBusyDialog } = useBusyDialog();

    const {
        openAlertDialog
    } = useContext(AppContext);


    return async (event) => {
        event.preventDefault();
        if (!isFormValid()) {
            openAlertDialog({ title: TRANS_MAP.missingInputs, content: TRANS_MAP.errorRequiredFields });
            return;
        }

        try {
            openBusyDialog({
                title: TRANS_MAP.busyTitle,
                content: TRANS_MAP.busyText
            });
            // Start Customization Process
            await startCustomize();
        } catch (error) {
            console.error(`startCustomize: ${error}`);
            openAlertDialog({ title: TRANS_MAP.warning, content: TRANS_MAP.messageTechnicalError, infos: error.message })
        }
    }
}

export default useCustomize;