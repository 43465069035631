const FR_TRANSLATION = {
    title: "Titre",
    appTitle: "Customization On Demand",
    appDescription: "Description de l'application",
    homePage: "Accueil",
    auditTrailPage: "Journal d'audit",
    printingsPage: "Impressions",
    logoutMenu: "Déconnexion",

    customizationDataBox: "Données de personnalisation",
    shipOrderLabel: "Livraison sortante",
    countryLabel: "Pays",
    materialLabel: "Produit",
    batchLabel: "Lot",
    quantityLabel: "Quantité",
    printButton: "Imprimer",
    customizeButton: "Personnaliser",

    printerSelectionBox: "Sélection de l'imprimante",
    A4PrinterLable: "Imprimante A4",
    zebraPrinterLabel: "Imprimante étiquettes",

    auditTrailMenu: "Journal d'audit",
    customizationMenu: "Personnalisation",

    userMyProfile: "Mon profil",
    userContactInfo: "Informations de contact",
    userSite: "Site",
    userEmail: "Email",

    printingStatusTable: "Etat d'impression",
    printingsLabel: "Impressions",
    documentType: "Type de document",
    partNumber: "Numéro de document",
    languages: "Langue",
    action: "Action",
    status: "Statut",
    format: "Format",
    validate: "Valider",

    "status-open": "Non imprimé",
    "status-aws-bad-product": "Article inconnu",
    "status-aws-bad-batch": "Lot inconnu",
    "status-sap-no-custo": "Pas de personnalisation pour ce pays et cet article",
    "status-aws-pdf-missing": "PDF manquant",
    "status-aws-pdf-multiple": "Multiples documents PDF trouvés pour ces données",
    "print-validated": "Validé",
    "status-sap-missing-doctype": "Type de document manquant dans la table des lots",
    "print-initiated": "Impression(s) lancée(s)",
    "no-document": "Aucun document pour le couple docType/Lot",
    "document-missing": "Document manquant",
    "receiving-plant-length": "Mauvaise taille pour le ReceivingPlant",
    "status-sap-batch-managed-required": "Lot manquant pour cette personnalisation",
    packageInsert: "Package Insert",
    outerContainerLabel: "Label",

    itemsLabel: "Items",
    documentsLabel: "Documents",

    messageNoCustoNeeded: "Pas de personnalisation pour ce pays et cet article",
    messageNoDataForShipOrder: "Pas de données article et lot pour cet ordre d'expédition",
    messageBadItem: "EAN ou produit inconnu. Merci de vérifier le champ Produit ou entrer manuellement le code produit.",
    messageBadBatch: "EAN et Lot inconnu du référentiel SAP (YMTAMATCT)",
    messageTechnicalError: "Erreur lors de la connexion aux données",
    messagePDFMissing: "Le document PDF est manquant (Resource Center).",
    messagePDFMultiple: "Multiples documents PDF trouvés pour ce mater (Resource Center).",

    busyTitle: "Action en cours",
    busyText: "Nous recherchons les documents de personnalisation, merci de patienter...",
    shiporderBusyText: "Nous recherchons les details de cet ordre d'expédition, merci de patienter...",

    errorRequiredFields: "Merci de renseigner les champs obligatoires.",

    auditTrailTitle: "Journal d'audit",
    filterAll: "Toutes opérations",
    filterValidated: "Validées",
    filterNotValidated: "Non validées",
    filterFailed: "Echec",
    resetAllFilters: "Réinitialiser tout",
    auditTrailTableTitle: "Historique",
    dateLabel: "Date",
    modifiedByLabel: "Modifié par",
    siteLabel: "Site",
    printingStatusLabel: "Statut d'impression",
    searchTooltip: "Rechercher",

    sessionTimeOut: "Votre session a expiré. Veuillez actualiser la page pour continuer.",
    sortLabel: "Trier les données",
    exportLabel: "Exporter les données",
    clearDateLabel: "Effacer les dates sélectionnées",

    inputShipOrderLabel: "Saisir une livraison sortante (optionnel)",
    inputCountryLabel: "Saisir un pays ou une livraison sortante (obligatoire)",
    inputMaterialLabel: "Saisir un produit (obligatoire)",
    inputBatchLabel: "Saisir un lot (optionnel)",

    ConfirmValidateTitle: "Confirmation",
    ConfirmValidate: "Une ou plusieurs impressions n'ont pas le statut Impression(s) lancée(s) \n Voulez-vous continuer ?",
    Confirm: "Confirmer",
    Cancel: "Annuler",
    apply: "Appliquer",

    loadingMessage: "Chargement des données...",
    download: "Télécharger",
    preparingDatas: "Préparation des données à exporter...",
    datasReady: "Fichier prêt à être téléchargé",

    tileTitleOut: "Personnalisation des livraisons sortantes",
    tileSubtitle: "Customization on demand",
    tileInfo: "v2.0",
    tileTitleIn: "Personnalisation des livraisons entrantes",


    labelRef: "Référence de l'étiquette",
    massCopiesUpdate: "Définir le nombre de copies pour tous les items sélectionnés:",
    copies: "copie(s)",
    copiesLabel: "Copies",
    copiesToDoLabel: "Copie(s) à réaliser",
    copiesPrintedLabel: "Copie(s) déjà réalisée(s)",
    outboundDelivery: "Livraison sortante",
    quantity: "Quantité",
    missingInputs: "Saisie(s) manquante(s)",
    unExpectedTechnicalError: "Une erreur technique inattendue est survenue.",
    printedQuantities: "Impressions réalisées",
    documentNumber: "Numéro du document",

    loadMoreDatas: "Charger plus de données",

    warning: "Attention",

    outboundDeliveries: "Customisation de l'expédition",
    inboundLabelling: "Customisation de la réception",
    customization: "Personnalisation",

    purchaseOrderLabel: "Livraison entrante",
    receivingPlantLabel: "Site de Réception",
    manufacturingDateLabel: "Date de fabrication",
    expirationDateLabel: "Date d'expiration",
    serialNbLabel: "Numéro de série",
    inboundQuantityLabel: "Quantité / Unités logistiques",

    inputPurchaseOrderLabel: "Saisir une livraison entrante (optionnel)",
    inputReceivingPlantLabel: "Saisir un site de réception SAP (obligatoire)",
    inputManufacturingDateLabel: "Saisir une date de fabrication dd.mm.YYYY (optionnel)",
    inputExpirationDateLabel: "Saisir une date d'expiration dd.mm.YYYY (optionnel)",
    inputSerialNbLabel: "Saisir un numéro de série (optionnel)",
    inputBatchInboundLabel: "Saisir un lot (optionnel)",
    inputQuantityLabel: "(optionnel) - Par défaut à 1",

    errorBatchOrSerial: "Merci de renseigner soit le lot soit le numéro de série",
}

export default FR_TRANSLATION;
