import { useContext, useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactExport from 'react-export-excel';

import * as audit from '@/libs/AuditTrailOperations';
import { AppContext } from '@/context/AppContext';
import TRANS_MAP from '@/i18n/TransMap';
import CONST from '@/const';
import * as utils from '@/libs/utils';
import './AuditTrail.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DialogExport = (props) => {
    const { t } = useTranslation();

    const { filters } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(true);
    const [parsedDatas, setParsedDatas] = useState(null);

    let xlsHeaders = [
        { id: "material", label: t(TRANS_MAP.itemsLabel), key: CONST.dbFields.item },
        { id: "shiporder", label: t(TRANS_MAP.outboundDelivery), key: CONST.dbFields.shiporder },
        { id: "receiving_plant", label: t(TRANS_MAP.receivingPlantLabel), key: CONST.dbFields.receiving_plant },
        { id: "serial_number", label: t(TRANS_MAP.serialNbLabel), key: CONST.dbFields.serial_number },
        { id: "updated_at", label: t(TRANS_MAP.dateLabel), key: CONST.dbFields.updatedAt },
        { id: "country", label: t(TRANS_MAP.countryLabel), key: CONST.dbFields.country },
        { id: "batch", label: t(TRANS_MAP.batchLabel), key: CONST.dbFields.batch },
        { id: "modified_by", label: t(TRANS_MAP.modifiedByLabel), key: CONST.dbFields.modifiedBy },
        { id: "document_type", label: t(TRANS_MAP.documentType), key: CONST.dbFields.documentType },
        { id: "part_number", label: t(TRANS_MAP.partNumber), key: CONST.dbFields.partNumber },
        { id: "label_part", label: t(TRANS_MAP.partNumber), key: CONST.dbFields.labelPart },
        { id: "languages", label: t(TRANS_MAP.languages), key: CONST.dbFields.languages },
        { id: "quantity", label: t(TRANS_MAP.copiesLabel), key: CONST.dbFields.quantity },
        { id: "status", label: t(TRANS_MAP.printingStatusLabel), key: CONST.dbFields.status },
    ];
    const colMode = (props.mode === audit.AVAILABLE_MODE.IN) ? CONST.columnsAuditTrailInboundExport : CONST.columnsAuditTrailOutbound;
    xlsHeaders = xlsHeaders.filter(el => colMode.includes(el.id));

    const filenameDate = utils.dateFromTimestamp({ rawDate: Math.floor((Date.now() / 1000)) });
    const filename = `COD_Audit-trail_${props.mode}_${filenameDate}`;

    const parseFilters = useCallback(() => {
        let filterQuery = "";
        for (const [key, value] of Object.entries(filters)) {
            if (value)
                filterQuery += `&${key}=${value}`
        }
        filterQuery += `&mode=${props.mode}`;
        return filterQuery;
    }, [props.mode, filters]);

    useEffect(() => {
        setIsLoading(true);
        
        async function fetchAllData() {
            const response = await audit.getAuditTrailDatas({
                filters: parseFilters(),
                disableLimit: true
            })
    
            setParsedDatas(parseDatas(response));
            setIsLoading(false);
        }

        fetchAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const parseDatas = (datas) => {
        for (let data of datas) {
            data.document_type = utils.getDocTypeAbbrFromCode(data.document_type);
            data.updated_at = utils.dateFromTimestamp({ rawDate: data.updated_at });
            data.status = t(data.status);
            data.part_number = data.part_number ? data.part_number : data.label_part;
        }
        return datas;
    }

    return (
        <div>

            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t(TRANS_MAP.exportLabel)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {isLoading
                            ?
                            t(TRANS_MAP.preparingDatas)
                            :
                            t(TRANS_MAP.datasReady)
                        }
                    </DialogContentText>
                </DialogContent>
                {isLoading && parsedDatas === null
                    ?
                    <DialogActions>
                        <div id="dialog-linear-progress"><LinearProgress /></div>
                    </DialogActions>
                    :
                    <ExcelFile
                        element={
                            <DialogActions>
                                <Button id="export-dl-btn" onClick={() => props.handleClose()}>{t(TRANS_MAP.download)}</Button>
                            </DialogActions>
                        }
                        filename={filename}>
                        <ExcelSheet data={parsedDatas} name="COD_Audit-trail">
                            {
                                xlsHeaders.map((header, index) => (
                                    <ExcelColumn key={index} label={header.label} value={header.key} />
                                ))
                            }
                        </ExcelSheet>
                    </ExcelFile>
                }
            </Dialog>
        </div>
    );
}

export default DialogExport;
