import { API } from 'aws-amplify';
import CONST from '@/const';

// Use ignore next to avoid coverage issue with unused path: else if (operation_id)
/* istanbul ignore next */
export const getOperation = async ({ item, shiporder, operation_id, batch, serial_number }) => {
    let request = `${CONST.crudRoutes.operations}`
    if (shiporder && item && batch)
        request = `${request}?shiporder=${shiporder}&batch=${batch}&item=${item}`
    else if (shiporder && item && serial_number)
        request = `${request}?shiporder=${shiporder}&serial_number=${serial_number}&item=${item}`
    else if (operation_id)
        request = `${request}/${operation_id}`
    return API.get(process.env.REACT_APP_AMPLIFY_API_NAME, request);
}

export const createOperation = async ({ user, country, item, material, ean, batch, shiporder, receiving_plant, serial_number, quantity, status, mode }) => {
    return API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.crudRoutes.operations, {
        body: {
            created_by: user.attributes.email,
            country,
            item: item ? item : material,
            ean: ean ? ean : item,
            batch,
            shiporder,
            receiving_plant,
            serial_number,
            quantity,
            status: status ? status : CONST.status.open,
            mode,
        }
    });
}

export const updateOperation = async ({ user, operation, status, isValidated, quantity }) => {
    const request = `${CONST.crudRoutes.operations}/${operation.id}`
    return API.post(process.env.REACT_APP_AMPLIFY_API_NAME, request, {
        body: {
            modified_by: user.attributes.email,
            status_message: status,
            is_validated: isValidated,
            quantity,
        }
    });
}

// Create each printing entry from auditTrailDatas, and return the new rows for futher use
export const handlePrintingsCreation = async ({ operation, RCDocumentsDatas, GLTDocumentsDatas }) => {
    return Promise.all([
        ...RCDocumentsDatas.map(async (doc) => createPrinting({
            operation,
            material: doc.material,
            document_type: doc.documentType,
            languages: doc.languages,
            part_number: doc.partNumber,
            temporary_url: doc.temporaryUrl,
            status: doc.status,
        })),
        ...GLTDocumentsDatas.map(async (doc) => createPrinting({
            operation,
            material: doc.material,
            document_type: doc.documentType,
            languages: doc.language,
            label_part: doc.labelPart,
            status: doc.status,
            manufacturer: doc.manufacturer,
            manufacturer_country: doc.manufacturerCountry
        }))
    ]);
}

export const createPrinting = async ({ operation, material, document_type, languages, part_number, label_part, temporary_url, status, manufacturer, manufacturer_country }) => {
    console.log( {
        operation_id: parseInt(operation.id),
        material,
        document_type,
        languages,
        part_number,
        label_part,
        temporary_url,
        status_message: status,
        manufacturer,
        manufacturer_country
    })
    return API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.crudRoutes.printings, {
        body: {
            operation_id: parseInt(operation.id),
            material,
            document_type,
            languages,
            part_number,
            label_part,
            temporary_url,
            status_message: status,
            manufacturer,
            manufacturer_country
        }
    });
}

// Use ignore next to avoid coverage issue with unused path: if (printing_id)
/* istanbul ignore next */
export const getPrinting = async ({ printing_id, operation_id }) => {
    let request = CONST.crudRoutes.printings;
    if (printing_id)
        request = `${CONST.crudRoutes.printings}/${printing_id}`;
    else if (operation_id)
        request = `${CONST.crudRoutes.operations}/${operation_id}/printings`;

    return API.get(process.env.REACT_APP_AMPLIFY_API_NAME, request);
}

export const updatePrinting = async ({ printing_id, status, quantity, document_type, languages, part_number, label_part, temporary_url }) => {
    const request = `${CONST.crudRoutes.printings}/${printing_id}`;
    return API.post(process.env.REACT_APP_AMPLIFY_API_NAME, request, {
        body: {
            status_message: status,
            quantity,
            document_type,
            languages,
            part_number,
            label_part,
            temporary_url
        }
    });
}


// AUDIT TRAIL PAGE
export const getFilterCounts = async (mode) => {
    const reqTotal = `${CONST.crudRoutes.printings}?count=true&mode=${mode}`;
    const reqValid = `${CONST.crudRoutes.printings}?status=${CONST.status.printValidated}&count=true&mode=${mode}`;
    const reqWarning = `${CONST.crudRoutes.printings}?status=${getNotValidatedStatusStr()}&count=true&mode=${mode}`;
    const reqError = `${CONST.crudRoutes.printings}?status=${getErrorStatusStr()}&count=true&mode=${mode}`;

    const totalNb = await API.get(process.env.REACT_APP_AMPLIFY_API_NAME, reqTotal);
    const validNb = await API.get(process.env.REACT_APP_AMPLIFY_API_NAME, reqValid);
    const warningNb = await API.get(process.env.REACT_APP_AMPLIFY_API_NAME, reqWarning);
    const errorNb = await API.get(process.env.REACT_APP_AMPLIFY_API_NAME, reqError);

    return { totalNb, validNb, warningNb, errorNb };
}

export const getErrorStatusStr = () => {
    return [
        CONST.status.badProduct,
        CONST.status.badBatch,
        CONST.status.noCusto,
        CONST.status.multiplePDF,
        CONST.status.missingPDF,
        CONST.status.missingDocType,
        CONST.status.noDocument,
        CONST.status.documentMissing,
    ].join(',');
}

export const getNotValidatedStatusStr = () => {
    return [
        CONST.status.open,
        CONST.status.printInitiated,
    ].join(',');
}

export const AVAILABLE_MODE = {
    IN: "in",
    OUT: "out"
}

export const getAuditTrailDatas = async ({ filters, disableLimit = false }) => {
    let request;
    if (disableLimit) {
        request = CONST.crudRoutes.audittrail;
        /* istanbul ignore next */
        if (filters) filters = filters.replace("&", "?")
    } else
        request = `${CONST.crudRoutes.audittrail}?limit=${CONST.auditTrail.limitValue}`;

    /* istanbul ignore next */
    if (filters) request += filters;
    return API.get(process.env.REACT_APP_AMPLIFY_API_NAME, request);
}
