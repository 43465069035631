const TRANS_MAP = {
    title: "title",
    appTitle: "appTitle",
    appDescription: "appDescription",
    homePage: "homePage",
    auditTrailPage: "auditTrailPage",
    printingsPage: "printingsPage",
    logoutMenu: "logoutMenu",
    customizationDataBox: "customizationDataBox",
    shipOrderLabel: "shipOrderLabel",
    countryLabel: "countryLabel",
    materialLabel: "materialLabel",
    batchLabel: "batchLabel",
    quantityLabel: "quantityLabel",
    printButton: "printButton",
    customizeButton: "customizeButton",
    printerSelectionBox: "printerSelectionBox",
    A4PrinterLable: "A4PrinterLable",
    zebraPrinterLabel: "zebraPrinterLabel",
    auditTrailMenu: "auditTrailMenu",
    customizationMenu: "customizationMenu",
    userMyProfile: "userMyProfile",
    userContactInfo: "userContactInfo",
    userSite: "userSite",
    userEmail: "userEmail",
    printingStatusTable: "printingStatusTable",
    printingsLabel: "printingsLabel",
    documentType: "documentType",
    partNumber: "partNumber",
    languages: "languages",
    action: "action",
    status: "status",
    format: "format",
    validate: "validate",
    "status-open": "status-open",
    badProduct: "badProduct",
    "status-sap-bad-batch": "status-sap-bad-batch",
    "status-sap-no-custo": "status-sap-no-custo",
    "status-aws-pdf-missing": "status-aws-pdf-missing",
    "status-aws-pdf-multiple": "status-aws-pdf-multiple",
    "print-validated": "print-validated",
    "status-sap-missing-doctype": "status-sap-missing-doctype",
    "print-initiated": "print-initiated",
    "no-document": "no-document",
    "document-missing": "document-missing",
    "receiving-plant-length": "receiving-plant-length",
    "status-sap-batch-managed-required": "status-sap-batch-managed-required",
    packageInsert: "packageInsert",
    outerContainerLabel: "outerContainerLabel",
    itemsLabel: "itemsLabel",
    documentsLabel: "documentsLabel",
    messageNoCustoNeeded: "messageNoCustoNeeded",
    messageNoDataForShipOrder: "messageNoDataForShipOrder",
    messageBadItem: "messageBadItem",
    messageBadBatch: "messageBadBatch",
    messageTechnicalError: "messageTechnicalError",
    messagePDFMissing: "messagePDFMissing",
    messagePDFMultiple: "messagePDFMultiple",
    busyTitle: "busyTitle",
    busyText: "busyText",
    shiporderBusyText: "shiporderBusyText",
    errorRequiredFields: "errorRequiredFields",
    auditTrailTitle: "auditTrailTitle",
    filterAll: "filterAll",
    filterValidated: "filterValidated",
    filterNotValidated: "filterNotValidated",
    filterFailed: "filterFailed",
    resetAllFilters: "resetAllFilters",
    auditTrailTableTitle: "auditTrailTableTitle",
    dateLabel: "dateLabel",
    modifiedByLabel: "modifiedByLabel",
    siteLabel: "siteLabel",
    printingStatusLabel: "printingStatusLabel",
    searchTooltip: "searchTooltip",
    sessionTimeOut: "sessionTimeOut",
    sortLabel: "sortLabel",
    exportLabel: "exportLabel",
    clearDateLabel: "clearDateLabel",
    inputShipOrderLabel: "inputShipOrderLabel",
    inputCountryLabel: "inputCountryLabel",
    inputMaterialLabel: "inputMaterialLabel",
    inputBatchLabel: "inputBatchLabel",
    ConfirmValidateTitle: "ConfirmValidateTitle",
    ConfirmValidate: "ConfirmValidate",
    Confirm: "Confirm",
    Cancel: "Cancel",
    apply: "apply",
    loadingMessage: "loadingMessage",
    download: "download",
    preparingDatas: "preparingDatas",
    datasReady: "datasReady",
    tileTitleOut: "tileTitleOut",
    tileSubtitle: "tileSubtitle",
    tileInfo: "tileInfo",
    tileTitleIn: "tileTitleIn",
    labelRef: "labelRef",
    massCopiesUpdate: "massCopiesUpdate",
    copies: "copies",
    copiesLabel: "copiesLabel",
    copiesToDoLabel: "copiesToDoLabel",
    copiesPrintedLabel: "copiesPrintedLabel",
    outboundDelivery: "outboundDelivery",
    quantity: "quantity",
    missingInputs: "missingInputs",
    unExpectedTechnicalError: "unExpectedTechnicalError",
    printedQuantities: "printedQuantities",
    documentNumber: "documentNumber",
    warning: "warning",
    loadMoreDatas: "loadMoreDatas",
    outboundDeliveries: "outboundDeliveries",
    inboundLabelling: "inboundLabelling",
    customization: "customization",

    purchaseOrderLabel: "purchaseOrderLabel",
    receivingPlantLabel: "receivingPlantLabel",
    manufacturingDateLabel: "manufacturingDateLabel",
    expirationDateLabel: "expirationDateLabel",
    serialNbLabel: "serialNbLabel",
    inboundQuantityLabel: "inboundQuantityLabel",

    inputPurchaseOrderLabel: "inputPurchaseOrderLabel",
    inputReceivingPlantLabel: "inputReceivingPlantLabel",
    inputManufacturingDateLabel: "inputManufacturingDateLabel",
    inputExpirationDateLabel: "inputExpirationDateLabel",
    inputSerialNbLabel: "inputSerialNbLabel",
    inputBatchInboundLabel: "inputBatchInboundLabel",
    inputQuantityLabel: "inputQuantityLabel",

    errorBatchOrSerial: "errorBatchOrSerial",
}

export default TRANS_MAP;
