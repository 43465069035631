import { useEffect, useContext } from 'react';
import { AppContext } from '@/context/AppContext';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Header from '@/components/Common/Header';
import Footer from '@/components/Common/Footer';
import LoadingBackdrop from '@/components/Common/LoadingBackdrop';
import Navbar from '@/components/Common/Navbar';
import Dialog from '@/components/Common/Dialog';

import Home from '@/components/Home';
import HomeOutbound from '@/components/Outbound/Home';
import AuditTrailOutbound from '@/components/Outbound/AuditTrail';
import PrintingsOutbound from '@/components/Outbound/Printings';
import HomeInbound from '@/components/Inbound/Home';
import AuditTrailInbound from '@/components/Inbound/AuditTrail';
import PrintingsInbound from '@/components/Inbound/Printings';

import { amplifyConfig } from '@/amplify.config';
import CONST from '@/const';

import '@/App.css';

Amplify.configure(amplifyConfig);

function App() {
    const {
        message,
        openDialog,
        isAlert,
        user, setUser
    } = useContext(AppContext);

    useEffect(() => {
        /* istanbul ignore next */ 
        Hub.listen('auth', async ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    await afterSignIn();
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.error('Sign in failure', data);
                    break;
                default:
                    break;
            }
        });

        const setUserOrSignin = async () => {
            try {
                let infos = await Auth.currentUserInfo();
                if (infos === null)
                    signIn();
                else
                    setUser(infos);
            } catch (error) {
                /* istanbul ignore next */
                console.error({ func: "setUserOrSignin", error });
            }
        }
        setUserOrSignin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUser]);

    async function signIn() {
        try {
            await Auth.federatedSignIn({
                provider: "ciam"
            });
        } catch (error) {
            /* istanbul ignore next */ 
            console.error({ func: "signIn", error });
        }
    }

    async function afterSignIn() {
        try {
            setUser(await Auth.currentUserInfo());
        } catch (error) {
            /* istanbul ignore next */ 
            console.error({ func: "afterSignIn", error });
        }
    }

    return (
        <Grid container
            direction="column"
            justifyContent="flex-start"
            id="app-container"
        >
            <Dialog open={openDialog} message={message} alert={isAlert} />
            <Header />
            {user ?
                <Router>
                    <Navbar />
                    <Switch>
                        <Route exact path={CONST.frontRoutes.home}>
                            <Home />
                        </Route>
                        <Route exact path={CONST.frontRoutes.homeOutbound}>
                            <HomeOutbound />
                        </Route>
                        <Route exact path={CONST.frontRoutes.auditTrailOutbound}>
                            <AuditTrailOutbound />
                        </Route>
                        <Route path={CONST.frontRoutes.printingsOutbound}>
                            <PrintingsOutbound />
                        </Route>
                        <Route exact path={CONST.frontRoutes.homeInbound}>
                            <HomeInbound />
                        </Route>
                        <Route exact path={CONST.frontRoutes.auditTrailInbound}>
                            <AuditTrailInbound />
                        </Route>
                        <Route path={CONST.frontRoutes.printingsInbound}>
                            <PrintingsInbound />
                        </Route>
                        <Redirect to='/' />
                    </Switch>
                </Router>
                :
                <LoadingBackdrop />
            }
            <Footer />
        </Grid>
    );
}

export default App;
