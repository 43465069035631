import { React } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import TRANS_MAP from '@/i18n/TransMap';

const PrintingsTableToolbar = (props) => {
    const { numSelected } = props;
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {t(TRANS_MAP.itemsLabel)}
                </Typography>
            )}

        </Toolbar>
    );
};

PrintingsTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default PrintingsTableToolbar;
