import React from 'react';
import Grid from '@mui/material/Grid';
import AuditTrailTable from './AuditTrailTable/Table';
import AuditTrailTableToolbar from './AuditTrailTable/TableToolBar';
import AuditTrailFilters from './AuditTrailTable/TableFilters';
import './AuditTrail.css'

const AuditTrail = ({ mode }) => {
    return (
        <Grid container
            direction="column"
            id="audit-trail-body"
        >
            <Grid container
                direction="column"
                id="audit-trail-header"
            >
                <AuditTrailFilters mode={mode} />
            </Grid>
            <AuditTrailTableToolbar mode={mode} />
            <Grid
                id="audit-trail-table-container">
                <AuditTrailTable mode={mode} />
            </Grid>
        </Grid>
    );
}

export default AuditTrail;
