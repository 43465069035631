import CONST from '@/const';

export const removeAIM = (code) => {
    if (code) {
        code = code.trim();
        if (code.startsWith(']')) code = code.substring(3);
    }
    return code;
}

export const parseItem = (item) => {
    let code = item.trim();
    if (code.length === 14 && !isNaN(code)) return { matnr: undefined, ean: code }; //EAN Code
    else return { matnr: code, ean: undefined };
}

export const formatMaterialInput = (code) => {
    let item = "";
    let newBatch = "";
    code = code.trim();
    if (!code) {
        item = code;
    } else if (code.startsWith(']d') || code.startsWith(']D') || code.startsWith(']C1')) {
        code = code.substring(3);
        //AIM identifiers for item number
        //   const idItem1 = '01';
        //   const idItem2 = '02';
        //AIM identifier for expiry date
        //   const idExpiryDate = '17';
        //AIM identifier for lot and serial
        //   const idLot = '10';
        //   const idSerial = '21';
        while (code.length > 0) {
            const id = code.substring(0, 2);
            code = code.substring(2);
            if (CONST.AIMIdentifer.items.includes(id)) {
                item = code.substring(0, 14);
                code = code.substring(14);
            } else if (id === CONST.AIMIdentifer.expiryDate) {
                //skipping expiring date
                code = code.substring(6);
            } else if (id === CONST.AIMIdentifer.lot || id === CONST.AIMIdentifer.serial) {
                newBatch = code;
                code = '';
            }
        }
    } else if (code.indexOf(']') === 0) {
        item = removeAIM(code);
    } else {
        item = code;
    }

    return { item, newBatch };
}

const findDocType = (findKey, findValue, returnKey) => {
    for (const key of Object.keys(CONST.docTypes)) {
        if (CONST.docTypes[key][`${findKey}`] === findValue) {
            return CONST.docTypes[key][returnKey];
        }
    }
    return findValue;
}

export const getDocTypeAbbrFromCode = (code) => findDocType("code", code, "abbr");

export const getDocTypeLabelFromCode = (code) => findDocType("code", code, "label");

export const getDocTypeCodeFromAbbr = (abbr) => findDocType("abbr", abbr, "code");

const getDocTypeNameFromCode = (code) => findDocType("code", code, "name");

export const getDocumentTypeIcon = (docType) => {
    try {
        docType = getDocTypeNameFromCode(docType);
        if (docType) {
            let imgSrc = CONST.docTypes[docType]['img'];
            return <img src={imgSrc} alt="document type icon" className="format-logos" />;
        }
        return <></>;
    } catch (error) /* istanbul ignore next */ {
        console.error(error);
        return <></>
    }
}

export const dateFromTimestamp = ({ rawDate, short }) => {
    let options;
    if (short) /* istanbul ignore next */ {
        options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };
    } else {
        options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
    }
    // parseInt give the timestamp in seconds
    // check if the date is timestamp or already in format dd/mm/YY 
    let date = parseInt(rawDate) < 31 ? new Date(rawDate) : new Date(parseInt(rawDate) * 1000);
    return (
        date.toLocaleDateString(navigator.language, options)
    );
}

export const hasError = (status) => ![CONST.status.printInitiated, CONST.status.printValidated, CONST.status.open].includes(status);

export const isRCDoc = (docType) => {
    return [CONST.docTypes.PI.code, CONST.docTypes.OCL.code].includes(docType)
}

export const isGLTDoc = (docType) => {
    return [CONST.docTypes.RA.code, CONST.docTypes.LL.code].includes(docType)
}

export const isEmptyOrSpaces = (str) => str === undefined || str === null || str.match(/^ *$/) !== null;