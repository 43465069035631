import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@/context/AppContext';

const CodDialog = (props) => {
    const {
        isAlert, setIsAlert,
        openDialog, setOpenDialog,
        setMessage
    } = useContext(AppContext);

    const { t } = useTranslation();

    const handleClose = () => {
        setOpenDialog(false);
        setIsAlert(false);
        setMessage({
            title: "",
            content: "",
            infos: ""
        });
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t(props.message.title)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(props.message.content)}

                    </DialogContentText>
                    {props.message.infos &&
                        <DialogContentText id="alert-dialog-description">
                            <br />Details:<br />
                            {props.message.infos}
                        </DialogContentText>
                    }
                </DialogContent>
                {isAlert
                    ?
                    <DialogActions>
                        <Button onClick={handleClose}>
                            OK
                        </Button>
                    </DialogActions>
                    :
                    <div id="dialog-progress"><CircularProgress /></div>
                }
            </Dialog>
        </div>
    );
}

export default CodDialog;
