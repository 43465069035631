import React, { useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import TRANS_MAP from "@/i18n/TransMap";
import { AppContext } from '@/context/AppContext';

import './Home.css'

const Home = ({ form }) => {
    const { resetContext, setOpenDialog } = useContext(AppContext)

    const { t } = useTranslation();

    // Reset most of the Context states to default
    useEffect(() => {
        setOpenDialog(false);
        resetContext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Grid container
            id="home-body"
            direction="row"
            justifyContent="space-around"
            alignItems="center"
        >
            <Grid item xs="auto"
                id="home-paper-grid">
                <Paper
                    id="home-paper">

                    {/* Form Header */}
                    <Grid container
                        id="home-form-header"
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <QrCode2Icon id="home-form-header-icon" fontSize="large" />
                        <Typography>
                            {t(TRANS_MAP.customizationDataBox)}
                        </Typography>
                    </Grid>

                    <Divider />

                    {/* Form Body */}
                    <div
                        id="home-form-body">
                        {form}
                    </div>

                </Paper>
            </Grid>

            <Grid item xs={4}
                id="home-cod-logo-item">
                <img src="/assets/logo-custom-tool.png" id="home-cod-logo" alt="COD Logo" />
            </Grid>
        </Grid>
    );
}

export default Home;
