import { useState, createContext, useContext } from 'react';
import CONST from '@/const';

export const AppContext = createContext();

export const useCodContext = () => useContext(AppContext);

export const AppProvider = (props) => {
    // Global
    const [user, setUser] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [countQuantities, setCountQuantities] = useState(null);
    const [message, setMessage] = useState({
        title: "",
        content: "",
        infos: ""
    });
    const [isAlert, setIsAlert] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // Outbound Form Inputs
    const [shiporder, setShiporder] = useState("");
    const [country, setCountry] = useState("");
    const [material, setMaterial] = useState("");
    const [batch, setBatch] = useState("");
    const [outSerialNb, setOutSerialNb] = useState("");

    // Inbound Form Inputs
    const [purchaseOrder, setPurchaseOrder] = useState("");
    const [receivingPlant, setReceivingPlant] = useState("");
    const [manufacturingDate, setManufacturingDate] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [serialNb, setSerialNb] = useState("");
    const [inboundField, setInboundField] = useState({});
    const [quantity, setQuantity] = useState(1);

    // Printings
    const [operation, setOperation] = useState(null);
    const [printings, setPrintings] = useState([]);
    const [printsPerDoc, setPrintsPerDoc] = useState(null);

    // Audit Trail
    const [auditTrailDatas, setAuditTrailDatas] = useState([]);
    const [filters, setFilters] = useState({});
    const [order, setOrder] = useState(CONST.order.asc);
    const [sort, setSort] = useState(CONST.sortParameters.date);
    const [tabFilter, setTabFilter] = useState(CONST.tabFilters.all);
    const [research, setReasearch] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [filterCounts, setFilterCounts] = useState({
        totalNb: 0,
        validNb: 0,
        warningNb: 0,
        errorNb: 0,
    });

    // Utils
    const resetOutboundFormStates = () => {
        setShiporder("");
        setCountry("");
        setMaterial("");
        setBatch("");
        setOutSerialNb("");
        setDelivery(null);
        setCustomer(null);
        setCountQuantities(null)
    }

    const resetInboundFormStates = () => {
        setPurchaseOrder("");
        setReceivingPlant("");
        setMaterial("");
        setBatch("");
        setManufacturingDate("");
        setExpirationDate("");
        setSerialNb("");
        setQuantity(1);
        setInboundField({});
    }

    const resetSort = () => {
        setOrder(CONST.order.asc);
        setSort(CONST.sortParameters.date);
    }

    const resetTabFilter = () => {
        setTabFilter(CONST.tabFilters.all)
    }

    const resetDateRangeFilter = () => {
        setDateRange([null, null]);
    }

    const resetAuditTrailStates = () => {
        resetSort();
        resetDateRangeFilter();
        setTabFilter(CONST.tabFilters.all);
        setReasearch("");

    }

    const resetContext = () => {
        resetOutboundFormStates();
        resetInboundFormStates();
        resetAuditTrailStates();
        setPrintsPerDoc(0);
        setPrintings([]);
        setFilters({});
    }

    const openAlertDialog = ({ title, content, infos }) => {
        setIsAlert(true);
        setMessage({
            title,
            content,
            infos
        });
        setOpenDialog(true);
    }

    const closeDialog = () => {
        setIsAlert(false);
        setMessage({
            title: "",
            content: "",
            infos: ""
        });
        setOpenDialog(false);
    }

    const updateFilters = ({ key, value }) => {
        setFilters({
            ...filters,
            [key]: value,
        })
    }

    const resetFilters = () => {
        resetAuditTrailStates();
        setFilters({});
    }

    return (
        <AppContext.Provider value={{
            message, setMessage,
            isAlert, setIsAlert,
            openDialog, setOpenDialog,
            openAlertDialog, closeDialog,
            user, setUser,
            shiporder, setShiporder,
            country, setCountry,
            material, setMaterial,
            batch, setBatch,
            outSerialNb, setOutSerialNb,
            delivery, setDelivery,
            customer, setCustomer,
            countQuantities, setCountQuantities,
            order, setOrder,
            sort, setSort,
            tabFilter, setTabFilter,
            filterCounts, setFilterCounts,
            research, setReasearch,
            dateRange, setDateRange,
            auditTrailDatas, setAuditTrailDatas,
            filters, setFilters, updateFilters, resetFilters,
            operation, setOperation,
            printings, setPrintings,
            printsPerDoc, setPrintsPerDoc,
            resetOutboundFormStates,
            resetInboundFormStates,
            resetContext,
            resetSort,
            resetTabFilter,
            resetDateRangeFilter,
            purchaseOrder,setPurchaseOrder,
            receivingPlant, setReceivingPlant,
            manufacturingDate, setManufacturingDate,
            expirationDate, setExpirationDate,
            serialNb, setSerialNb,
            quantity, setQuantity,
            inboundField, setInboundField,
        }}>
            {props.children}
        </AppContext.Provider>
    );
}
