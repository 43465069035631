import React, { useContext } from 'react';
import { API } from 'aws-amplify';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TuneIcon from '@mui/icons-material/Tune';

import { AppContext } from '@/context/AppContext';
import * as utils from '@/libs/utils';
import * as custo from '@/libs/Customize';
import * as audit from '@/libs/AuditTrailOperations';
import TRANS_MAP from '@/i18n/TransMap';
import CONST from '@/const';
import useCustomize from "@/hooks/useCustomize";
import useBusyDialog from "@/hooks/useBusyDialog";
import useKeyPress from "@/hooks/useKeyPress";

const Form = () => {
    // Context states
    const {
        openDialog, setOpenDialog,
        openAlertDialog, closeDialog,
        user,
        setOperation,
        setPrintings,
        shiporder, setShiporder,
        country, setCountry,
        material, setMaterial,
        batch, setBatch,
        outSerialNb, setOutSerialNb,
        delivery, setDelivery,
        customer, setCustomer,
        setCountQuantities, countQuantities,
    } = useContext(AppContext);

    const history = useHistory();
    const { t } = useTranslation();

    const { openBusyDialog } = useBusyDialog();

    const PH_SHIPORDER = t(TRANS_MAP.inputShipOrderLabel);
    const PH_COUNTRY = t(TRANS_MAP.inputCountryLabel);
    const PH_MATERIAL = t(TRANS_MAP.inputMaterialLabel);
    const PH_BATCH = t(TRANS_MAP.inputBatchLabel);
    const PH_SERIALNB = t(TRANS_MAP.inputSerialNbLabel);

    const LABEL_SHIPORDER = t(TRANS_MAP.shipOrderLabel);
    const LABEL_COUNTRY = t(TRANS_MAP.countryLabel);
    const LABEL_MATERIAL = t(TRANS_MAP.materialLabel);
    const LABEL_BATCH = t(TRANS_MAP.batchLabel);
    const LABEL_SERIALNB = t(TRANS_MAP.serialNbLabel);
    const LABEL_CUST_BTN = t(TRANS_MAP.customizeButton);

    const SHIPORDER_FIELD_NAME = "shiporder";
    const COUNTRY_FIELD_NAME = "country";
    const MATERIAL_FIELD_NAME = "material";
    const BATCH_FIELD_NAME = "batch";
    const SERIALNB_FIELD_NAME = "serialNb";

    const shiporderInputHandler = (e) => {
        // Shiporder field allows only numbers
        if (e.target.value !== '' && !Number(e.target.value)) {
            return;
        }
        setShiporder(e.target.value);
    }

    const countryInputHandler = (e) => {
        // Country field allows only letters and has to be uppercase
        let value = e.target.value
        if (value !== '' && /[^a-zA-Z]/.test(value)) {
            return;
        }
        setCountry(value.toUpperCase());
    }

    const materialInputHandler = (e) => setMaterial(e.target.value);
    const batchInputHandler = (e) => setBatch(e.target.value);
    const serialNbInputHandler = (e) => setOutSerialNb(e.target.value.toUpperCase());

    const resetInputs = () => {
        setShiporder("");
        setCountry("");
        setMaterial("");
        setBatch("");
        setOutSerialNb("");
    }

    // Format material and batch inputs on focus out (e.g. in case of barcode scan)
    const handleMaterialInput = (e) => {
        try {
            const { item, newBatch } = utils.formatMaterialInput(e.target.value);
            setMaterial(item);
            if (newBatch)
                setBatch(newBatch);
        } catch (error) {
            /* istanbul ignore next */
            console.error(`Error formating material input: ${e}`);
        }
    }

    // Retrieve datas (country, delivery, customer) from shiporder on focus out
    const handleShiporder = async (e) => {
        let { value } = e.target;
        /* istanbul ignore next */
        if (!value)
            return;
        let cleanShiporder = utils.removeAIM(value);

        try {
            openBusyDialog({
                title: TRANS_MAP.busyTitle,
                content: TRANS_MAP.busyText
            });
            const req = await API.get(process.env.REACT_APP_AMPLIFY_API_NAME, `${CONST.apiRoutes.getDatasFromShiporder}?documentID=${cleanShiporder}`);
            if (req.noDataMessage) {
                openAlertDialog({ title: TRANS_MAP.warning, content: req.noDataMessage });
                resetInputs();
            } else {
                setCountry(req.country);
                setDelivery(req.delivery);
                setCustomer(req.customer);
                setCountQuantities(req.countQuantities);
                closeDialog();
            }
        } catch (error) {
            openAlertDialog({ title: TRANS_MAP.warning, content: TRANS_MAP.messageTechnicalError, infos: error.message });
            console.error(`API error: ${error}`);
        }
    }

    // Check that required fields are filled
    const isFormValid = () => country.trim() && material.trim();

    const getCountQuantity = (product_number, lot_number) => {
        if (!countQuantities)
            return 0;
        let countQuantity = countQuantities.filter(count => count.product === product_number && count.batch === lot_number)
        if (countQuantity.length === 0)
            countQuantity = countQuantities.filter(count => count.product === product_number && count.batch === "")
        if (countQuantity.length === 1)
            return countQuantity[0][CONST.dbFields.quantity];
        return 0;
    }

    // Check that serial number or batch are filled
    const isBatchOrSerialNb = () => !utils.isEmptyOrSpaces(outSerialNb) || !utils.isEmptyOrSpaces(batch);

    const startCustomize = async () => {
        if (!isBatchOrSerialNb()) {
            openAlertDialog({ title: TRANS_MAP.missingInputs, content: TRANS_MAP.errorBatchOrSerial });
            return;
        }

        const { matnr, ean } = utils.parseItem(material);
        // Check that item (product/material) and batch exist in SAP, and convert ean (if present) to product_number
        try {
            const response = await API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.apiRoutes.valid, {
                response: true,
                body: {
                    product_number: matnr,
                    lot_number: batch,
                    ean: ean,
                    mode: audit.AVAILABLE_MODE.OUT
                }
            });

            let { product_number, lot_number, batch_managed_required } = response.data;
            if (!product_number || batch_managed_required) {
                const errorStatus = batch_managed_required ? CONST.status.batchManagedRequired : CONST.status.badProduct;
                const badOperation = await audit.createOperation({ user, country, item: matnr, material, ean, batch, serial_number: outSerialNb ?? "", shiporder, status: errorStatus, mode: audit.AVAILABLE_MODE.OUT });
                await audit.createPrinting(({ operation: badOperation, material, status: errorStatus }));
                openAlertDialog({ title: TRANS_MAP.warning, content: errorStatus });
                return;
            }

            let isNewOperation = true;
            // Get operation from shiporder+item+batch if exists, otherwise, create operation entry
            let newOperation;
            if (shiporder && product_number && lot_number) // TODO: Add serial_number in retrieve ?
                newOperation = await audit.getOperation({ item: product_number, shiporder, batch: lot_number });
            if (shiporder && product_number && outSerialNb)
                newOperation = await audit.getOperation({ item: product_number, shiporder, serial_number: outSerialNb });
            if (!newOperation || newOperation.length === 0)
                newOperation = await audit.createOperation({ user, country, item: product_number, ean, batch: lot_number ?? "", serial_number: outSerialNb ?? "", shiporder, quantity: getCountQuantity(product_number, lot_number), mode: audit.AVAILABLE_MODE.OUT })
            else {
                isNewOperation = false;
                newOperation = newOperation[0];
                newOperation.quantity = getCountQuantity(product_number, lot_number);
                await audit.updateOperation({ user, operation: newOperation, quantity: getCountQuantity(product_number, lot_number) });
            }

            // Set operation in App Context for further use in Printings Page
            setOperation(newOperation);

            // Look for documents in YMTA_CTRY_CUSTO SAP Table
            let { custoForCountryRC, custoForCountryGLT, status } = await custo.getCustoForCountry({
                matnr: product_number, ean, country, customer, delivery, outSerialNb: !utils.isEmptyOrSpaces(outSerialNb) ? outSerialNb : undefined
            });

            // No document found, update operation status, stop here
            if (status && status === 404) {
                await audit.updateOperation({ user, operation: newOperation, status: CONST.status.noCusto })
                openAlertDialog({ title: TRANS_MAP.warning, content: CONST.status.noCusto });
                return;
            }

            let RCDocumentsDatas = [];
            let GLTDocumentsDatas = [];
            // Handle documents datas from Resource Center
            if (custoForCountryRC.length)
                RCDocumentsDatas = await custo.processResourceCenterDocuments(custoForCountryRC, lot_number);
            // Handle documents datas from GLT
            if (custoForCountryGLT.length)
                GLTDocumentsDatas = await custo.processGLTDocuments(custoForCountryGLT);
            let newPrintings = []
            let existingPrintings = []
            if (!isNewOperation) {
                // Retrieve existing printings for current operation
                existingPrintings = await audit.getPrinting({ operation_id: newOperation.id });
                // Update documents
                if (existingPrintings.length)
                    await custo.updateDocuments({ operation: newOperation, existingPrintings });
                // Check if totally new documents appeared in YMTA_CTRY_CUSTO SAP Table
                const { newGLTDocs, newRCDocs } = custo.checkNewDocuments({ existingPrintings, RCDocumentsDatas, GLTDocumentsDatas });
                // Create the new entries if needed
                newPrintings = await audit.handlePrintingsCreation({ operation: newOperation, RCDocumentsDatas: newRCDocs, GLTDocumentsDatas: newGLTDocs });
            } else {
                // Create Printings in DB if there are some custos
                newPrintings = await audit.handlePrintingsCreation({ operation: newOperation, RCDocumentsDatas, GLTDocumentsDatas });
            }
            // Check that we have printings to display in the Printings table. If not, dialog with error is shown
            let { documentsCheck, statusCheck } = custo.checkDocumentsDatas(RCDocumentsDatas, GLTDocumentsDatas);
            if (!documentsCheck) {
                openAlertDialog({ title: TRANS_MAP.warning, content: statusCheck });
                return;
            }

            // Set documents datas into AppContext to use the values in the printings page
            setPrintings([...existingPrintings, ...newPrintings]);
            setMaterial(product_number);
            setOpenDialog(false);
            // Move forward if there are custos (even if some documents are missing, it's shown in the printing table)
            history.push(CONST.frontRoutes.printingsOutbound);
        } catch (error) {
            throw Object.assign(
                new Error(error),
                { message: error.response?.data || error.message }
            );
        }
    }

    // Preflight when clicking Customize button
    const handleCustomize = useCustomize(isFormValid, startCustomize);

    // Handling material input if enter key is press
    const keyPress = useKeyPress(event => (event.charCode === CONST.keyboardKeys.ENTER && event.target.name === MATERIAL_FIELD_NAME && material[0] === "]"), handleMaterialInput)

    return (
        <Grid container
            id="cod-form-container"
            direction="column"
        >
            <form onSubmit={handleCustomize} onKeyPress={keyPress}>
                <label>
                    {LABEL_SHIPORDER}<br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            type="text"
                            maxLength={10}
                            name={SHIPORDER_FIELD_NAME}
                            onChange={shiporderInputHandler}
                            onBlur={handleShiporder}
                            placeholder={PH_SHIPORDER}
                            value={shiporder} />
                    </Grid>
                </label>

                <label>
                    {LABEL_COUNTRY}<span className="star-required">*</span><br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={COUNTRY_FIELD_NAME}
                            type="text"
                            maxLength={2}
                            onChange={countryInputHandler}
                            placeholder={PH_COUNTRY}
                            value={country}
                            disabled={openDialog || shiporder}
                            required
                        />
                    </Grid>
                </label>

                <label>
                    {LABEL_MATERIAL}<span className="star-required">*</span><br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={MATERIAL_FIELD_NAME}
                            type="text"
                            onChange={materialInputHandler}
                            onBlur={handleMaterialInput}
                            placeholder={PH_MATERIAL}
                            value={material}
                            required
                        />
                    </Grid>
                </label>

                <label>
                    {LABEL_BATCH}<br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={BATCH_FIELD_NAME}
                            type="text"
                            maxLength={10}
                            onChange={batchInputHandler}
                            placeholder={PH_BATCH}
                            value={batch}
                        />
                    </Grid>
                </label>

                <label>
                    {LABEL_SERIALNB}
                    <br />
                    <Grid container
                        className="cod-form-input-container">
                        <input
                            className="cod-form-input"
                            name={SERIALNB_FIELD_NAME}
                            type="text"
                            onChange={serialNbInputHandler}
                            placeholder={PH_SERIALNB}
                            value={outSerialNb}
                        />
                    </Grid>
                </label>

                <Grid item>
                    <Button type="submit" variant="contained" id="customize-btn">
                        <TuneIcon id="tune-icon" />
                        {LABEL_CUST_BTN}
                    </Button>
                </Grid>
            </form>
        </Grid>
    )
}

export default Form;
