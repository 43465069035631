import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

import TRANS_MAP from '@/i18n/TransMap';

const PrintingsDialog = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-confirmation"
                aria-describedby="alert-dialog-confirm-validate"
            >
                <DialogTitle id="alert-dialog-title">
                    {t(TRANS_MAP.ConfirmValidateTitle)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t(TRANS_MAP.ConfirmValidate)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleCancel} color="error" autoFocus>
                        {t(TRANS_MAP.Cancel)}
                    </Button>
                    <Button onClick={props.handleConfirm} color="primary">
                        {t(TRANS_MAP.Confirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PrintingsDialog;
