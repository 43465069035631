import React, { useContext } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

import { AppContext } from '@/context/AppContext';
import TRANS_MAP from '@/i18n/TransMap';
import CONST from '@/const';
import * as audit from '@/libs/AuditTrailOperations';

const AuditTrailTableHead = ({ mode }) => {
    const {
        order, setOrder,
        sort, setSort,
        filters, setFilters,
    } = useContext(AppContext);

    const { t } = useTranslation();

    const handleOrderBy = (param) => {
        // Default sort order
        let newOrder = CONST.order.asc;
        // If sort parameter has not changed, sort order is reversed
        /* istanbul ignore next */
        if (sort === param)
            newOrder = order === CONST.order.desc ? CONST.order.asc : CONST.order.desc;
        setSort(param);
        setOrder(newOrder);
        setFilters({
            ...filters,
            [CONST.auditTrail.sort]: param,
            [CONST.auditTrail.order]: newOrder
        });
    }

    const boxValue = order === CONST.order.desc ? 'sorted descending' : 'sorted ascending';

    let headCells = [
        {
            id: "material",
            disablePadding: false,
            label: t(TRANS_MAP.materialLabel),
        },
        {
            id: "batch",
            disablePadding: false,
            label: t(TRANS_MAP.batchLabel),
        },
        {
            id: "country",
            disablePadding: true,
            label: t(TRANS_MAP.countryLabel),
        },
        {
            id: "shiporder",
            disablePadding: false,
            label: t(TRANS_MAP.shipOrderLabel),
        },
        {
            id: "receiving_plant",
            disablePadding: false,
            label: t(TRANS_MAP.receivingPlantLabel),
        },
        {
            id: "serial_number",
            disablePadding: false,
            label: t(TRANS_MAP.serialNbLabel),
        },
        {
            id: "document_type",
            disablePadding: false,
            label: t(TRANS_MAP.documentType),
        },
        {
            id: "part_number",
            disablePadding: false,
            label: t(TRANS_MAP.partNumber),
        },
        {
            id: "languages",
            disablePadding: false,
            label: t(TRANS_MAP.languages),
        },
        {
            id: "status",
            disablePadding: false,
            label: t(TRANS_MAP.printingStatusLabel),
        },
        {
            id: "quantity",
            disablePadding: false,
            label: t(TRANS_MAP.copiesLabel),
        },
        {
            id: "modified_by",
            disablePadding: false,
            label: t(TRANS_MAP.modifiedByLabel),
        },
        {
            id: "updated_at",
            disablePadding: false,
            label: t(TRANS_MAP.dateLabel),
        },
    ];

    const colMode = (mode === audit.AVAILABLE_MODE.IN) ? CONST.columnsAuditTrailInbound : CONST.columnsAuditTrailOutbound;
    headCells = headCells.filter(el => colMode.includes(el.id));

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell className="table-header-cells"
                        key={index}
                        align="center"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        size="medium"
                    >
                        <TableSortLabel
                            active={sort === headCell.id}
                            direction={sort === headCell.id ? order : CONST.order.asc}
                            onClick={() => handleOrderBy(headCell.id)}
                        >
                            {headCell.label}
                            {sort === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>{boxValue}</Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default AuditTrailTableHead;
